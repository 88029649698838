import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from "./components/pages/Home"
import AboutMe from "./components/pages/AboutMe"
import Projects from "./components/pages/Projects"
import Experience from './components/pages/Experience';
import ContactMe from './components/pages/ContactMe';
import Sidebar from './components/Navbar/Sidebar';

function App() {
  return (
    <div className="App">
      <Router>
        <Sidebar/>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/aboutMe" component={AboutMe}></Route>
          <Route path="/projects" component={Projects}></Route>
          <Route path="/experience" component={Experience}></Route>
          <Route path="/contactMe" component={ContactMe}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
