import React from 'react';
import "./ProjectsSection.css"
import "../../App.css"
function ProjectsSection() {
    return (
        <div id="projectsContainer" className='projects-container'>
            <h1 className='projects-text'>
                My work
            </h1>
        </div>
    )
}

export default ProjectsSection;
