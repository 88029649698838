import React from 'react'
import '../../App.css'

function ContactMe() {
    return (
        <div>
            <h1 className='contactMe'>Contact Me</h1>
        </div>
    )
}

export default ContactMe
