import React from 'react'
import '../../App.css'

function Projects() {
    return (
        <div>
            <h1 className='projects'>Projects</h1>
        </div>
    )
}

export default Projects
