import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../pages/Projects.js'
import './Sidebar.css'

function Sidebar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <>
            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>

            <nav className={click ? 'sidebar active' : 'sidebar'}>
                <div className='sidebar-container'>
                    <Link to="/" className={click ? 'sidebar-logo active' : 'sidebar-logo'} onClick={closeMobileMenu}>
                        <div className='logo-container'>
                            <img className='logo-img' src="/images/logoMin.png" alt="awangLogo"></img>
                            <h1 className='logo-name'>Alex</h1>
                            <p className='logo-job'>Student</p>
                        </div>
                    </Link>

                    <ul className={click ? 'sidebar-list active' : 'sidebar-list'}>
                        <Link to="/aboutme">
                            <li className='sidebar-row'>
                                <div className='sidebar-row-icon'><i class="fas fa-user" /></div>
                                <div className='sidebar-row-text'>about</div>
                            </li>
                        </Link>
                        <Link to="/projects">
                            <li className='sidebar-row'>
                                <div className='sidebar-row-icon'><i class="fas fa-file-code" /></div>
                                <div className='sidebar-row-text'>projects</div>
                            </li>
                        </Link>
                        <Link to="/experience">
                            <li className='sidebar-row'>
                                <div className='sidebar-row-icon'><i class="fas fa-desktop" /></div>
                                <div className='sidebar-row-text'>experience</div>
                            </li>
                        </Link>
                        <Link to="/contactme">
                            <li className='sidebar-row'>
                                <div className='sidebar-row-icon'><i class="fas fa-address-book" /></div>
                                <div className='sidebar-row-text'>contact</div>
                            </li>
                        </Link>
                    </ul>

                    <ul className={click ? 'sidebar-socials active' : 'sidebar-socials'}>
                        <li>
                            <a className='social-links' rel="noreferrer" href="https://github.com/alexwangcoquitlam" target="_blank">
                                <i class="fab fa-github" />
                            </a>
                        </li>
                        <li>
                            <a className='social-links' rel="noreferrer" href="https://www.linkedin.com/in/alexander-wang-364504126/" target="_blank">
                                <i class="fab fa-linkedin-in" />
                            </a>
                        </li>
                    </ul>
                    <div className={click ? 'sidebar-footer active' : 'sidebar-footer'} style={{ width: "100%", height: "5%" }} />
                </div>
            </nav>
        </>
    )
}

export default Sidebar
