import React from 'react';
import {Link} from 'react-router-dom';
import './Button.css';

const STYLES = ['btn--primary', 'btn--outline'];

const SIZES = ['btn--medium', 'btn--large'];

const LINKS = ['/', '/projects', '/contactme', '/experience']

export const Button =({children, type, onClick, buttonStyle, buttonSize, buttonLink}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    const checkButtonLink = LINKS.includes(buttonLink) ? buttonLink : LINKS[0];

    return(
        <Link to={buttonLink} className="btn-mobile">
            <button 
            className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonLink}`} 
            onClick={onClick} 
            type={type}>
                {children}
            </button>
        </Link>
    );
};