import React from 'react'
import './AboutMe.css'

function AboutMe() {
  return (
    <div className='about-me-container'>
      <h1 className="aboutMe">About Me</h1>
    </div>
  )
}

export default AboutMe;